.traffic-grid-container {
  height: 100vh;
  width: 100%;
  background-color: rgb(81, 96, 137);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  padding: 0 8px;
}

.traffic-grid-container > .options {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 6px;
}

.showview-button {
  height: 28px;
  width: 64px;
  border: 0;
  background-color: rgb(33, 42, 60);
  outline: none;
  cursor: pointer;
}

.showview-button span {
  color: white;
}

.showview-button--active {
  background-color: rgb(81, 96, 137);
}

.showview-button--left {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.showview-button--right {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.traffic-grid-container > .timeline {
  display: flex;
  flex-direction: row;
  background-color: rgb(20, 25, 36);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 60px;
}

.timeline > .selected-time-container {
  display: flex;
  flex-direction: row;
  width: 170px;
  align-items: center;
  padding-left: 8px;
}

.timeline > .selected-time-container .selected-time {
  width: 100%;
  overflow: hidden;
}

.timeline > .selected-time-container .selected-time select {
  width: 100%;
  padding: 10px;
  font-weight: 600;
  border: none;
  outline: none;
  background-color: rgb(47, 59, 82);
  color: rgb(159, 165, 175);
  border-radius: 5px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.timeline > .timeline-history {
  flex: 1 1;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 20px;
}

.timeline > .timeline-history > .timeline-container {
  height: 85%;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.timeline > .timeline-history .timeline-barcode {
  border-left: 2px white solid;
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  position: relative;
}

.timeline > .timeline-history .timeline-barcode .timeline-barcode__title {
  position: absolute;
  color: white;
  font-size: 1rem;
  top: -20px;
}

.timeline-barcode__title--left {
  left: -16px;
}

.timeline-barcode__title--right {
  right: -16px;
}

.timeline-barcode--left {
  border-right: 2px white solid;
}

.timeline > .timeline-history .timeline-barcode > .timeline-barcode__line {
  border-right: 1px white solid;
  width: 25%;
  height: 50%;
}

.traffic-grid-container > .information-trucks {
  flex: 1;
  height: calc(100vh - 112px);
  display: flex;
  flex-direction: row;
}

.traffic-grid-container > .information-trucks .information-trucks__list {
  background-color: rgb(50, 65, 94);
  width: 180px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  border-bottom-left-radius: 10px;
  align-items: center;
}

.traffic-grid-container > .information-trucks .information-trucks__status {
  width: calc(100vw - 180px);
  background-color: rgb(33, 42, 60);
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.traffic-grid-container
  > .information-trucks
  .information-trucks__status
  .information-trucks__status__info {
  font-size: 20px;
  font-weight: bold;
  color: rgb(122, 127, 137);
}

.truck-item {
  display: flex;
  flex-direction: column;
  padding: 8px;
  align-items: baseline;
  height: 20%;
}

.truck-item > .truck-item__number {
  font-size: 14px;
}

.truck-item__number--truck {
  color: white;
  font-weight: 600;
}

.truck-item__number--trip {
  color: rgb(119, 129, 148);
}

.truck-item > .truck-item__info {
  display: flex;
  flex-direction: row;
  padding-top: 12px;
}

.truck-item > .truck-item__info > .truck-item__info-companyLogo {
  width: 60px;
  height: 60px;
}

.truck-item > .truck-item__info > .truck-item__info-companyLogo img {
  width: 100%;
  height: 100%;
  border-radius: 60px;
}

.truck-item > .truck-item__info > .truck-item__info-model {
  display: flex;
  flex-direction: column;
  padding-left: 8px;
}

.truck-item
  > .truck-item__info
  > .truck-item__info-model
  > .truck-item__info-model__icon {
  height: 30px;
  width: 80px;
}

.truck-item
  > .truck-item__info
  > .truck-item__info-model
  > .truck-item__info-model__icon
  img {
  width: 100%;
  height: 100%;
}

.truck-item
  > .truck-item__info
  > .truck-item__info-model
  > .truck-item__info-model__title {
  padding-top: 8px;
}

.truck-item__info-model__title--truck {
  color: rgb(119, 129, 148);
}

.truck-item__info-model__title--trip {
  color: white;
  font-weight: 600;
}
