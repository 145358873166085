.App {
  font-family: sans-serif;
  text-align: center;
  display: flex;
}
.space {
  width: 20%;
}
.square {
  width: 80%;
  display: flex;
}

.square-container {
  width: 80%;
  overflow: hidden;
}

.square-container .square {
  min-width: 4000px;
}

.division {
  border-right: 1px solid white;
  box-sizing: border-box;
}

.division:nth-child(1) {
  border-left: 1px solid white;
}

.vertical-line {
  border-right: 1px solid white;
  height: 5px;
  position: relative;
}
.vertical-line:last-child {
  border-right: none;
}
.triangle {
  margin: 0 auto;
  opacity: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 7px 0 7px;
  border-color: white transparent transparent transparent;
}
.triangle:hover {
  opacity: 1;
}
.dotted {
  content: '';
  position: absolute;
  height: 100vh;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 50%;
  border-left: 2px dotted white;
}

.division:hover {
  background-color: #3f4f6c;
}

.d-flex {
  display: flex;
}

.column {
  flex-direction: column;
}
.align-items-end  {
  align-items: flex-end;
}
.text-align-center {
  text-align: center;
  font-size: 12px;
}

.white {
  color: white;
}

.justify-content-center {
  justify-content: center;
}

.pt {
  padding: 5px 0;
}
